import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUserObservable: Observable<firebase.User | null>;
  currentUser: firebase.User;

  constructor(private angularFireAuth: AngularFireAuth) {
    this.currentUserObservable = this.angularFireAuth.authState;
    this.currentUserObservable.subscribe((user) => {
      this.currentUser = user;
    });
  }

  checkAuthData() {
    if (this.currentUser) {
      return;
    } else {
      this.angularFireAuth.signInAnonymously();
      return;
    }
  }

  authState(): Promise<firebase.User> {
    return this.angularFireAuth.authState.toPromise();
  }

  get isUserSingedIn(): boolean {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }

  get currentUserData(): firebase.User {
    return this.currentUser;
  }

  // get user claims for current user
  async getUserClaims(): Promise<any> {
    const token = await this.currentUser?.getIdTokenResult();
  }
  
}
