export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBvv0ZcpBHy5P4MrRNIiJSbnXDC5tH2cRM",
    authDomain: "waste-tracker-dev.firebaseapp.com",
    projectId: "waste-tracker-dev",
    storageBucket: "waste-tracker-dev.appspot.com",
    messagingSenderId: "517745649279",
    appId: "1:517745649279:web:cf53e7731be7bbde1ec14a",
    measurementId: "G-P515B6D8FQ"
  },
  langs: [
    { key: 'en-GB', name: 'English' },
    { key: 'de-CH', name: 'Deutsch' },
    { key: 'fr-CH', name: 'Français' },
    { key: 'it-CH', name: 'Italiano' },
  ]
};
