import { Basket, BasketItem } from './../../../models/shop/basket/baket.model';
import { BasketState } from './../../../models/enums/basket-state.enum';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from '../firestore-data.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import firebase from 'firebase/compat/app';
import { UserProfile } from 'src/app/core/models/user-profile.model';
import { StripeProductService } from '../stripe/stripe-product/stripe-product.service';
import { UserProfileService } from '../user-profile/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class BasketService extends FirestoreDataService<Basket> {
  isBasketOpen = false;
  isBasketOpenObservable: BehaviorSubject<boolean>;

  constructor(
    afs: AngularFirestore,
    private userProfileService: UserProfileService,
    private stripeProductService: StripeProductService
  ) {
    super('Baskets', afs);
    this.isBasketOpenObservable = new BehaviorSubject<boolean>(false);
  }

  getBasketState(): Observable<boolean> {
    return this.isBasketOpenObservable.asObservable();
  }
  setBasketState(newValue: boolean): void {
    this.isBasketOpen = newValue;
    this.isBasketOpenObservable.next(this.isBasketOpen);
  }

  toggleBasketState(): void {
    this.isBasketOpen = !this.isBasketOpen;
    this.isBasketOpenObservable.next(this.isBasketOpen);
  }


  async createNewBasketForCurrentUSer(): Promise<Basket> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    const newBasket: Basket = {
      state: BasketState.empty,
      ownerId: currentUserProfile.id,
    };
    const newBasketRef = await this.collection.add(newBasket);
    currentUserProfile.currentBasketId = newBasketRef.id;
    this.userProfileService.update(currentUserProfile, currentUserProfile.id);
    newBasket.id = currentUserProfile.currentBasketId;
    return newBasket;
  }

  async getCurrentBasket(): Promise<Basket> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    console.log(currentUserProfile);
    if (currentUserProfile.currentBasketId) {
      const basket = await this.getCurrentBasketAsPromise(currentUserProfile);
      return basket;
    } else {
      console.log('No current basket, creating new one');
      const basketToCreate: Basket = {
        ownerId: currentUserProfile.id,
        state: BasketState.empty,
      };
      const newBasket = await this.collection.add(basketToCreate);
      currentUserProfile.currentBasketId = newBasket.id;
      this.userProfileService.update(currentUserProfile, currentUserProfile.id);
      const basket = await this.getCurrentBasketAsPromise(currentUserProfile);
      return basket;
    }
  }

  async observeCurrentUserBasket(): Promise<Observable<Basket>> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    if (currentUserProfile.currentBasketId) {
      return this.getCurrentBasketAsObservable(currentUserProfile);
    } else {
      const basketToCreate: Basket = {
        ownerId: currentUserProfile.id,
        state: BasketState.empty,
      };
      const newBasket = await this.collection.add(basketToCreate);
      currentUserProfile.currentBasketId = newBasket.id;
      this.userProfileService.update(currentUserProfile, currentUserProfile.id);
      return this.getCurrentBasketAsObservable(currentUserProfile);
    }
  }

  getCurrentBasketAsPromise(currentUserProfile: UserProfile): Promise<Basket> {
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .get()
      .pipe(
        map((document) => {
          if (document.exists) {
            const data = document.data();
            const id = document.id;
            return { id, ...data };
          }
        })
      )
      .toPromise();
  }

  getCurrentBasketAsObservable(
    currentUserProfile: UserProfile
  ): Observable<Basket> {
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .get()
      .pipe(
        map((document) => {
          if (document.exists) {
            const data = document.data();
            const id = document.id;
            return { id, ...data };
          }
        })
      );
  }

  async addToBasket(productId, priceId, quantity): Promise<void> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    console.log('currentUserProfile', currentUserProfile);
    let currentBasket: Basket;
    if (currentUserProfile.currentBasketId) {
      currentBasket = await this.getCurrentBasketAsPromise(currentUserProfile);
    }
    console.log('currentBasket', currentBasket);
    if (!currentBasket) {
      const newBasket: Basket = {
        ownerId: currentUserProfile.id,
        state: BasketState.empty,
        basketItems: [],
      };
      const newBasketRef = await this.collection.add(newBasket);
      currentUserProfile.currentBasketId = newBasketRef.id;
      this.userProfileService.update(currentUserProfile, currentUserProfile.id);
      newBasket.id = currentUserProfile.currentBasketId;
      currentBasket = newBasket;
    }
    const basketItem = currentBasket.basketItems?.find(
      (item) =>
        item.stripeProduct.id === productId && item.stripePrice.id === priceId
    );
    if (basketItem) {
      basketItem.quantity += quantity;
    } else {
      const stripeProduct = await this.stripeProductService.getProduct(
        productId
      );
      const basketItemToSave: BasketItem = {
        stripeProduct,
        stripePrice: stripeProduct.prices.find((price) => price.id === priceId),
        quantity,
      };
      currentBasket.basketItems = currentBasket.basketItems || [];
      currentBasket.basketItems.push(basketItemToSave);
    }
    currentBasket.basketTotal = this.calculateBasketTotal(currentBasket);
    this.setBasketState(true);
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .set(currentBasket);
  }

  async appendToCurrentBasket(
    productId: string,
    priceId: string,
    quantity: number
  ): Promise<void> {
    const stripeProduct = await this.stripeProductService.getProduct(productId);
    const basketItem: BasketItem = {
      stripeProduct,
      stripePrice: stripeProduct.prices.find((price) => price.id === priceId),
      quantity,
    };
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    const currentBasket = await this.getCurrentBasketAsPromise(
      currentUserProfile
    );
    currentBasket.basketItems = currentBasket.basketItems || [];
    currentBasket.basketItems.push(basketItem);
    currentBasket.basketTotal = this.calculateBasketTotal(currentBasket);
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .set(currentBasket);
  }

  async changeQuanity(productId, priceId, byQuantity): Promise<void> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    const currentBasket = await this.getCurrentBasketAsPromise(
      currentUserProfile
    );
    const basketItem = currentBasket.basketItems.find(
      (item) =>
        item.stripeProduct.id === productId && item.stripePrice.id === priceId
    );
    basketItem.quantity = byQuantity + basketItem.quantity;
    currentBasket.basketTotal = this.calculateBasketTotal(currentBasket);
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .set(currentBasket);
  }

  calculateBasketTotal(basket: Basket): number {
    return basket.basketItems.reduce(
      (total, item) => total + item.stripePrice.unit_amount * item.quantity,
      0
    );
  }

  async removeBasketItem(productId, priceId): Promise<void> {
    const currentUserProfile: UserProfile =
      await this.userProfileService.getCurrentUser();
    const currentBasket = await this.getCurrentBasketAsPromise(
      currentUserProfile
    );
    const basketItemIndex = currentBasket.basketItems.findIndex(
      (item) =>
        item.stripeProduct.id === productId && item.stripePrice.id === priceId
    );
    currentBasket.basketItems.splice(basketItemIndex, 1);
    currentBasket.basketTotal = this.calculateBasketTotal(currentBasket);
    return this.collection
      .doc<Basket>(currentUserProfile.currentBasketId)
      .set(currentBasket);
  }
}
