import { UserProfile } from './../../../models/user-profile.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirestoreDataService } from '../firestore-data.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { arrayUnion, serverTimestamp } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends FirestoreDataService<UserProfile> {
  public currentUser: UserProfile;

  constructor(afs: AngularFirestore, private angularFireAuth: AngularFireAuth) {
    super('UserProfiles', afs);
  }

  async setUserProfile() {
    await this.getCurrentUser();
  }

  async createNewUser(uid: string): Promise<void> {
    await this.collection.doc<UserProfile>(uid).set({
      id: uid,
    });
    this.setUserProfile();
  }

  async getCurrentUser(): Promise<UserProfile> {
    const user = await this.angularFireAuth.currentUser;
    return this.collection
      .doc<UserProfile>(user.uid)
      .get()
      .pipe(
        map((document) => {
          if (document.exists) {
            const data = document.data();
            const id = document.id;
            this.currentUser = { id, ...data };
            return { id, ...data };
          }
        })
      )
      .toPromise();
  }

  async setCurrentUser(userProfile: UserProfile): Promise<void> {
    const user = await this.angularFireAuth.currentUser;
    await this.collection
      .doc<UserProfile>(user.uid)
      .set(userProfile, { merge: true });
    this.setUserProfile();
    return;
  }

  async logLastLogIn(): Promise<void> {
    const user = await this.angularFireAuth.currentUser;
    await this.collection
      .doc<UserProfile>(user.uid)
      .update({ lastLogInDate: serverTimestamp() });
    return;
  }
}
