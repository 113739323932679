import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { StripeSubscription } from 'src/app/core/models/shop/stripe/stripe-subscription.model';

@Injectable({
  providedIn: 'root'
})
export class StripeSubscriptionService {

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) { }


  async getActiveSubscriptions(): Promise<StripeSubscription[]>{
    const currentUser = await this.afAuth.currentUser;
    const subscriptions = await this.afs
      .collection('StripeCustomers')
      .doc(currentUser.uid)
      .collection<StripeSubscription>('subscriptions')
      .ref
      .where('status', '==', 'active')
      .get();
    return subscriptions.docs.map((doc) => doc.data());

  }

  getSubscriptions(currentUserId: string): Observable<StripeSubscription[]>{
    return this.afs
      .collection('StripeCustomers')
      .doc(currentUserId)
      .collection<StripeSubscription>('subscriptions')
      .snapshotChanges().pipe(
        map((changes) =>
          changes.map((a) => {
            const data = a.payload.doc.data();
            return data;
          })
        )
      );
  }
}
