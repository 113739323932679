import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take } from 'rxjs';
import { CookieAlertService } from 'src/app/core/services/cookie-alert/cookie-alert.service';
import { BasketService } from 'src/app/core/services/firestore-data/basket/basket.service';
import { UserProfileService } from 'src/app/core/services/firestore-data/user-profile/user-profile.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class CompanyInviteGuard  {
  constructor(
    private afAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private basketService: BasketService,
    private cookieAlertService: CookieAlertService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve, reject) => {
      // take first emitted value from authState
      this.afAuth.authState.pipe(take(1)).subscribe((user: firebase.User) => {
        if (user) {
          resolve(true);
        } else {
          // get roude :id
          const routeParams = route.params;
          const id = routeParams.id;
          console.log('routeParams', routeParams);
          // redirect to company join redirect page

          this.router.navigate(['/app/company/join-redirect', id]);
          resolve(false);
        }
      });
    });
  }
  
}
