import { UserProfileService } from './core/services/firestore-data/user-profile/user-profile.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './core/services/firestore-auth/auth/auth.service';
import { ThemeService } from './core/services/theme/theme.service';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import ls from '@livesession/sdk';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
    private angularFireAuth: AngularFireAuth,
    private userProfileService: UserProfileService
  ) {

    this.angularFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userProfileService.getCurrentUser();
        this.authService.getUserClaims();
        this.userProfileService.logLastLogIn();
      } else {
        // this.angularFireAuth.signInAnonymously();
      }
    });
  }

  async ngAfterViewInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.checkTheme();
    SplashScreen.hide();
  }

  async ngOnInit(): Promise<void> {
    await SplashScreen.show({
      showDuration: 3000,
      autoHide: true
    });
   
    // await StatusBar.setStyle({ style: Style.Light });
  }

  checkTheme() {
    this.themeService.checkPreferedColorScheme();
  }
}
