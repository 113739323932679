import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StripeSubscriptionService } from 'src/app/core/services/firestore-data/stripe/stripe-subscription/stripe-subscription.service';
import { UserProfileService } from 'src/app/core/services/firestore-data/user-profile/user-profile.service';
import { MeasurementService } from '../services/firestore-data/measurement/measurement.service';
import { CompanyService } from '../services/firestore-data/company/company.service';
import { UserProfile } from 'src/app/core/models/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class NoPremiumGuard  {
  constructor(
    private userProfileService: UserProfileService,
    private measurementService: MeasurementService,
    private stripeSubscriptionService: StripeSubscriptionService,
    private alertController: AlertController,
    private companyService: CompanyService,

    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise(async (resolve, reject) => {
        console.log('NoPremiumGuard');
        const currentUser = await this.userProfileService.getCurrentUser();
        const measurements = await this.measurementService.getListFilteredAndOrderedAsPromise('ownerId', '==', currentUser.id, 'createdAt', 'desc');
        const subscriptions = await this.stripeSubscriptionService.getActiveSubscriptions();
        if (subscriptions?.length > 0 
          || await this.isUserCompanyMember(currentUser)
          ) {
          resolve(true);
        } else if (measurements?.length >= 7){
          const alert = await this.alertController.create({
            header: 'Premium',
            message: $localize`'You have reached the limit of 7 measurements. Please subscribe to continue using the app.'`,
            buttons: [
              {
                text: $localize`'Cancel'`,
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  resolve(false);
                }
              }, {
                text: $localize`'Subscribe'`,
                handler: () => {
                  this.router.navigateByUrl('/app/add-subscription');
                  resolve(false);
                }
              }
            ]
          });
          await alert.present();
        } else {
          resolve(true);
        }
      });

  }

  async isUserCompanyMember(userProfile: UserProfile): Promise<boolean> {
    const currentUser = await this.userProfileService.getCurrentUser();
    const companies = await this.companyService.getListFiltered('viewerIds', 'array-contains', currentUser.id)
    if (companies.length > 0) {
      console.log('isUserCompanyMember', true);
      return true;
    } else {
      console.log('isUserCompanyMember', false);
      return false;
    }
  }


}


