import { UserProfile } from './../models/user-profile.model';
import { UserProfileService } from './../services/firestore-data/user-profile/user-profile.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class AdminPageGuard  {
  constructor(
    private afAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((user: firebase.User) => {
        if (user) {
          console.log('found user');
          this.userProfileService
            .getCurrentUser()
            .then((userProfile: UserProfile) => {
              console.log('user profile found checkin rights');
              if (userProfile?.admin) {
                console.log('user is admin');
                resolve(true);
              } else {
                console.log('user is not admin');
                this.router.navigateByUrl('/404');
                reject(false);
              }
            });
        } else {
          reject(false);
        }
      });
    });
  }
}
