import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { StripeSubscriptionService } from 'src/app/core/services/firestore-data/stripe/stripe-subscription/stripe-subscription.service';
import { UserProfileService } from 'src/app/core/services/firestore-data/user-profile/user-profile.service';
import { MeasurementService } from '../services/firestore-data/measurement/measurement.service';
import { CompanyService } from '../services/firestore-data/company/company.service';
import { sub } from 'date-fns';
import e from 'express';

@Injectable({
  providedIn: 'root'
})
export class NoProfessionalGuardGuard {
  constructor(
    private userProfileService: UserProfileService,
    // private measurementService: MeasurementService,
    private stripeSubscriptionService: StripeSubscriptionService,
    private alertController: AlertController,
    private companyService: CompanyService,

    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
      console.log('NoPremiumGuard');
      const currentUser = await this.userProfileService.getCurrentUser();
      const companies = await this.companyService.getListFilteredAndOrderedAsPromise('ownerId', '==', currentUser.id, 'displayName', 'asc');

      const subscriptions = await this.stripeSubscriptionService.getActiveSubscriptions();
      if (subscriptions?.length > 0
      ) {
        const name = subscriptions[0].items[0].price.product.name;
        // check if any active subscription is a professional plan
        for (const subscription of subscriptions) {
          const name = subscription.items[0].price.product.name;
          if (name === 'Company-Plan'
          // || isDevMode()
          ) {
            resolve(true);
          } else {
            this.showProfessionalAlert();
            resolve(false);
          }
        }
        
      } 
      // else if(isDevMode()){
      //   resolve(true);
      // } 
      else  {
        this.showProfessionalAlert();
        resolve(false);
        
      }
    });
  }

  async showProfessionalAlert() {
    const alert = await this.alertController.create({
      header: 'Company',
      message: $localize`'You need a company subscription to create a company.'`,
      buttons: [
        {
          text: $localize`'Cancel'`,
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: $localize`'Subscribe'`,
          handler: () => {
            this.router.navigateByUrl('/app/add-subscription');
          }
        }
      ]
    });
    await alert.present();
  }


}
