import { loadTranslations } from "@angular/localize";
import { environment } from "src/environments/environment";
import { LanguageService } from "./services/language/language.service";

export const initializeLanguage = (): Promise<void> | void => {
  const availableLocales = environment.langs.map(l => l.key);
  var locale = defineUserLanguage();
  console.log('loaded language: ' + locale);
  switch (locale) {
    case 'de' || 'de-CH':
      locale = 'de-CH';
      break;
    case 'fr' || 'fr-CH':
      locale = 'fr-CH';
      break;
    case 'it' || 'it-CH':
      locale = 'it-CH';
      break;
    default:
      console.log('default language triggered: ' + locale);
      break;
  }
  console.log('switched language: ' + locale);

  window.document.documentElement.lang = locale;


  if (locale !== availableLocales[0]) {
    // fetch resources for runtime translations. this could also point to an API endpoint
    const lang = locale.split('-')[0];
    fetch(`assets/i18n/messages.${lang}.json`)
      .then((response) => response.json())
      .then((result) => {
        loadTranslations(result.translations);
      })
      .catch(() => console.log(`language ${lang} not found, fallback to default`));
  }

}

export const defineUserLanguage = () => {
  console.log('defineUserLanguage');
  const availableLocales = environment.langs.map(l => l.key);
  const cookieLang = document.cookie
    .split('; ')
    .find(c => c.startsWith('lang='))
    ?.split('=')[1];
console.log('cookieLang: ' + cookieLang);
  // check order is: cookie -> navigator (browser) -> default (first defined in environment)
  let locale = cookieLang ??
    (availableLocales.find((l) => navigator.language.startsWith(l)) ??
      availableLocales[1]);
  return locale;
}
