import { FirestoreDataDocumentInterface } from './../../models/firestore-data-document.model';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  DocumentReference,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { arrayUnion, serverTimestamp } from '@angular/fire/firestore'
import { fi } from 'date-fns/locale';



export interface FirestoreDataServiceInterface<T> {
  get(id: string): Observable<T>;
  list(): Observable<T[]>;
  add(item: T): Promise<DocumentReference>;
  set(item: T): Promise<void>;
  delete(id: string): Promise<void>;
}

export class FirestoreDataService<T extends FirestoreDataDocumentInterface>
  implements FirestoreDataServiceInterface<T>
{
  protected collection: AngularFirestoreCollection<T>;

  constructor(protected uri: string, protected afs: AngularFirestore) {
    this.collection = this.afs.collection(this.uri);
  }

  get(id: string): Observable<T> {
    return this.collection
      .doc<T>(id)
      .snapshotChanges()
      .pipe(
        map((action) => {
          if (action.payload.exists) {
            const data = action.payload.data();
            const docId = action.payload.id;
            return { id: docId, ...data };
          }
        })
      );
  }

  getAsPromise(id: string): Promise<T> {
    return this.collection
      .doc<T>(id)
      .get()
      .pipe(
        map((action) => {
          if (action.exists) {
            const data = action.data();
            const docId = action.id;
            return { id: docId, ...data };
          }
        })
      )
      .toPromise();
  }

  list(): Observable<T[]> {
    return this.collection.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  add(item: T): Promise<DocumentReference> {
    // const timestamp = serverTimestamp();
    // const localTimestamp = new Date();
    item.lastEdited = serverTimestamp();
    return this.collection.add(item);
  }

  set(item: T): Promise<void> {
    item.lastEdited = serverTimestamp();
    return this.collection.doc<T>(item.id).set(item, { merge: true });
  }

  update(data: any, id: string): Promise<void> {
    data.lastEdited = new Date();
    return this.collection.doc<T>(id).update(data);
  }

  delete(id: string): Promise<void> {
    const docRef = this.collection.doc<T>(id);
    return docRef.delete();
  }

  listOrdered(
    orderby: string,
    direction: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) => ref.orderBy(orderby, direction)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFiltered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any
  ): Observable<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) => ref.where(filterBy, operator, condition)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  async getListFiltered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any
  ): Promise<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) => ref.where(filterBy, operator, condition)
    );
    const snapshot = await col.get().toPromise();
    return snapshot.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  }

  listFilteredBy2Properties(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    filterBy2: string,
    operator2: firebase.firestore.WhereFilterOp,
    condition2: any
  ): Observable<T[]> {
    console.log('listFiltedby2Triggered', this.uri);
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref
          .where(filterBy, operator, condition)
          .where(filterBy2, operator2, condition2)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFilteredBy2PropertiesAndOrdered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    filterBy2: string,
    operator2: firebase.firestore.WhereFilterOp,
    condition2: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    console.log('listFiltedby2Triggered', this.uri);
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref
          .where(filterBy, operator, condition)
          .where(filterBy2, operator2, condition2)
          .orderBy(orderby, direction)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFilteredBy2PropertiesAnd2Ordered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    filterBy2: string,
    operator2: firebase.firestore.WhereFilterOp,
    condition2: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection,
    orderby2: string,
    direction2: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    console.log('listFiltedby2Triggered', this.uri);
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref
          .where(filterBy, operator, condition)
          .where(filterBy2, operator2, condition2)
          .orderBy(orderby, direction)
          .orderBy(orderby2, direction2)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFilteredBy3PropertiesAndOrdered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    filterBy2: string,
    operator2: firebase.firestore.WhereFilterOp,
    condition2: any,
    filterBy3: string,
    operator3: firebase.firestore.WhereFilterOp,
    condition3: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    console.log('listFiltedby2Triggered', this.uri);
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref
          .where(filterBy, operator, condition)
          .where(filterBy2, operator2, condition2)
          .where(filterBy3, operator3, condition3)
          .orderBy(orderby, direction)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFilteredAndOrdered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref.where(filterBy, operator, condition).orderBy(orderby, direction)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  listFilteredAnd2Ordered(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection,
    orderby2: string,
    direction2: firebase.firestore.OrderByDirection
  ): Observable<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref.where(filterBy, operator, condition).orderBy(orderby, direction).orderBy(orderby2, direction2)
    );
    return col.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        })
      )
    );
  }

  async getListFilteredAndOrderedAsPromise(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    orderby: string,
    direction: firebase.firestore.OrderByDirection
  ): Promise<T[]> {
    const col: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri,
      (ref) =>
        ref.where(filterBy, operator, condition).orderBy(orderby, direction)
    );
    const snapshot = await col.get().toPromise();
    return snapshot.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  }

  listWithParentCheck(parentCollection: string): Observable<T[]> {
    const collection: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri
    );
    return collection.snapshotChanges().pipe(
      map((changes) =>
        changes.map((a) => {
          if (a) {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;

            const parentCol = this.afs.collection(parentCollection, (ref) =>
              ref.where(firebase.firestore.FieldPath.documentId(), 'in', data.parentIds)
            );
            parentCol
              .get()
              .toPromise()
              .then((snapshot) => {
                console.log('snapshot', snapshot.docs.length);
                if (snapshot.empty) {
                  data.hasParent = false;
                } else {
                  data.hasParent = true;
                }
                console.log('data', data);
              });
            return data;
          }
        })
      )
    );
  }

  listFilteredByTwoOrFilters(
    filterBy: string,
    operator: firebase.firestore.WhereFilterOp,
    condition: any,
    filterBy2: string,
    operator2: firebase.firestore.WhereFilterOp,
    condition2: any
  ): Observable<T[]> {
    const col1: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri, (ref) =>
        ref.where(filterBy, operator, condition)
    );
    const col2: AngularFirestoreCollection<T> = this.afs.collection(
      this.uri, (ref) =>
      ref.where(filterBy2, operator2, condition2)
    );

    return combineLatest([col1.valueChanges(), col2.valueChanges()]).pipe(
      map(([col1, col2]) => {
        return col1.concat(col2);
      })
    );
  }


 // update with arrayUnion
  updateWithArrayUnion(id: string, field: string, value: any): Promise<void> {
    const doc: AngularFirestoreDocument<any> = this.collection.doc<T>(id);
    const data = { [field]: arrayUnion(value) };
    return doc.update(data);
  }

 
}
