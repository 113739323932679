import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from 'src/app/core/services/firestore-data/firestore-data.service';
import { Company } from 'src/app/custom/model/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends FirestoreDataService<Company> {

  constructor(
    afs: AngularFirestore,
  ) {
    super('Company', afs);
  }
}
