import {
  StripePrice,
  StripeProduct,
} from '../../../../models/shop/stripe/stripe-product.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreDataService } from '../../firestore-data.service';

@Injectable({
  providedIn: 'root',
})
export class StripeProductService extends FirestoreDataService<StripeProduct> {
  constructor(afs: AngularFirestore) {
    super('StripeProducts', afs);
  }

  listProducts(): Observable<StripeProduct[]> {
    const collection = this.afs.collection('StripeProducts', (ref) =>
      ref.where('active', '==', true)
    );
    return collection.snapshotChanges().pipe(
      map((changes) =>
        changes.map((action) => {
          if (action.payload) {
            const data: any = action.payload.doc.data();
            data.id = action.payload.doc.id;
            data.prices = [];
            action.payload.doc.ref
              .collection('prices')
              .get()
              .then((snapshot) => {
                data.prices = [];
                snapshot.forEach((doc) => {
                  const price = doc.data();
                  price.id = doc.id;
                  // console.log(price);
                  if (price.active) {
                    data.prices.push(price);
                  }
                  // data.prices.push(price);
                });
              });
            return data;
          }
        })
      )
    );
  }

  async getProduct(id: string): Promise<StripeProduct> {
    const collection = this.afs.collection('StripeProducts', (ref) =>
      ref.where('active', '==', true)
    );
    const product = await collection.ref.doc(id).get();
    if (product.exists) {
      const data: any = product.data();
      data.id = product.id;
      data.prices = [];
      await product.ref
        .collection('prices')
        .get()
        .then((snapshot) => {
          data.prices = [];
          snapshot.forEach((doc) => {
            const price = doc.data();
            if (price.active) {
              price.id = doc.id;
              console.log(price);
              data.prices.push(price);
            }
          });
        });
      return data;
    }
    return null;
  }

  getProductPrices(productId: string): Observable<StripePrice[]> {
    const collection = this.afs
      .collection('StripeProducts')
      .doc(productId)
      .collection('prices', (ref) => ref.where('active', '==', true));
    return collection.snapshotChanges().pipe(
      map((changes) =>
        changes.map((action) => {
          if (action.payload) {
            const data: any = action.payload.doc.data();
            data.id = action.payload.doc.id;
            data.prices = [];
            action.payload.doc.ref
              .collection('prices')
              .get()
              .then((snapshot) => {
                data.prices = [];
                snapshot.forEach((doc) => {
                  const price = doc.data();
                  price.id = doc.id;
                  // console.log(price);
                  data.prices.push(price);
                });
              });
            return data;
          }
        })
      )
    );
  }
}
