import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { FirestoreDataService } from 'src/app/core/services/firestore-data/firestore-data.service';
import { Measurement } from 'src/app/custom/model/measurement.model';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService extends FirestoreDataService<Measurement>{

  constructor(afs: AngularFirestore) {
    super('Measurements', afs);
  }


}
