import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { defineUserLanguage } from '../../app.initializers';

import { CookieService } from './../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private cookieService: CookieService) {}

  getCurrentLanguage(): string {
    console.log('getCurrentLanguage');
    let cookie = this.cookieService.getCookie('lang');
    console.log('cookie: ' + cookie);
    if (!cookie) {
      console.log('no cookie found');
      const lang = defineUserLanguage();
      this.cookieService.setCookie({name: 'lang', value: lang});
      cookie = lang;
    }
    console.log('cookie: ' + cookie);
    switch (cookie) {
      case 'de' || 'de-CH':
        cookie = 'de-CH';
        break;
      case 'fr ' || 'fr-CH':
        cookie = 'fr-CH';
        break;
      case 'it' || 'it-CH':
        cookie = 'it-CH';
        break;
      default:
        break;
    }
    console.log('switched language: ' + cookie);
    return cookie;
  }
  changeLanguage(lang: string, omitReload: boolean = false): void {
    this.cookieService.setCookie({ name: 'lang', value: lang });
    if (!omitReload) {
      window.location.reload();
    }
  }

  getAvailableLanguages(): { key: string; name: string }[] {
    return environment.langs;
  }
}
