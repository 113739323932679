import { CompleteUserProfileGuard } from './core/guards/complete-user-profile/complete-user-profile.guard';
// import { MissingUserInfoGuardGuard } from './core/guards/missing-user-info/missing-user-info-guard.guard';
// import { SetupHerbyWifiModule } from './core/components/client/devices/herby/setup-herby-wifi/setup-herby-wifi.module';
import { AdminPageGuard } from './core/guards/admin-page.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { NoPremiumGuard } from './custom/guards/no-premium.guard';
import { CompanyInviteGuard } from './custom/guards/company-invite.guard';
import { NoProfessionalGuardGuard } from './custom/guards/no-professional-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full',
  },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./core/pages/home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: 'admin',
    loadChildren: () =>
      import('./core/pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AdminPageGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard, CompleteUserProfileGuard],
  },
  {
    path: 'app/measurements/new/:restaurant-id',
    loadChildren: () => import('./custom/pages/app/measurements/measurement-edit/measurement-edit.module').then( m => m.MeasurementEditPageModule),
    canActivate: [AuthGuard, NoPremiumGuard]
  },
  {
    path: 'app/measurements/edit/:id',
    loadChildren: () => import('./custom/pages/app/measurements/measurement-edit/measurement-edit.module').then( m => m.MeasurementEditPageModule),
    canActivate: [AuthGuard, NoPremiumGuard]
  },
  // {
  //   path: 'tabs/:id',
  //   loadChildren: () => import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
  //   canActivate: [],
  // },
  {
    path: 'prev/:id',
    loadChildren: () =>
      import('./core/pages/main/main.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuard],
    // canActivate: [AngularFireAuthGuard]
  },
  // {
  //   path: 'app/dashboard',
  //   loadChildren: () =>
  //     import('./core/pages/app/dashboard/dashboard.module').then(
  //       (m) => m.DashboardPageModule
  //     ),
  //   canActivate: [AuthGuard, Com],
  // },
  {
    path: 'basket',
    loadChildren: () =>
      import('./core/pages/client/shop/basket/basket.module').then(
        (m) => m.BasketPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./core/pages/client/shop/checkout/checkout.module').then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./core/pages/client/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: 'knowledge/:id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'knowledge/plants/:id',
    loadChildren: () =>
      import('./core/pages/published/published.module').then(
        (m) => m.PublishedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'checkout-success/:id',
    loadChildren: () =>
      import(
        './core/pages/client/shop/checkout/checkout-success/checkout-success.module'
      ).then((m) => m.CheckoutSuccessPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'ask-categories',
    loadChildren: () => import('./custom/pages/client/on-boarding/ask-categories/ask-categories.module').then( m => m.AskCategoriesPageModule)
  },
  {
    path: 'profile-details',
    loadChildren: () => import('./custom/pages/admin/profiles/profile-details/profile-details.module').then( m => m.ProfileDetailsPageModule)
  },

  {
    path: 'app/add-subscription',
    loadChildren: () => import('./custom/pages/app/subscription/add-subscription/add-subscription.module').then( m => m.AddSubscriptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./custom/pages/app/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },

  {
    path: 'app/company/edit/:id',
    loadChildren: () => import('./custom/pages/app/company/company-edit/company-edit.module').then( m => m.CompanyEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app/company/join/:id',
    loadChildren: () => import('./custom/pages/app/company/company-join/company-join.module').then( m => m.CompanyJoinPageModule),
    canActivate: [CompanyInviteGuard]
  },
  {
    path: 'app/company/join-redirect/:id',
    loadChildren: () => import('./custom/pages/app/company/company-join-redirect/company-join-redirect.module').then( m => m.CompanyJoinRedirectPageModule)
  },
  {
    path: 'company-restaurant-add-user',
    loadChildren: () => import('./custom/pages/app/company/company-restaurant-add-user/company-restaurant-add-user.module').then( m => m.CompanyRestaurantAddUserPageModule)
  },
  {
    path: 'app/company/create',
    loadChildren: () => import('./custom/pages/app/company/ask-company-name/ask-company-name.module').then( m => m.AskCompanyNamePageModule),
    canActivate: [AuthGuard, NoProfessionalGuardGuard]
  },
  {
    path: 'app/company/add-category',
    loadChildren: () => import('./custom/pages/app/company/company-add-category/company-add-category.module').then( m => m.CompanyAddCategoryPageModule),
    canActivate: [AuthGuard]
  },
  
  
  // {
  //   path: 'list-tags',
  //   loadChildren: () => import('./custom/pages/admin/tags/list-tags/list-tags.module').then( m => m.ListTagsPageModule)
  // },
  // {
  //   path: 'edit-tag',
  //   loadChildren: () => import('./custom/pages/admin/tags/edit-tag/edit-tag.module').then( m => m.EditTagPageModule)
  // }



  // {
  //   path: ':parent/:id',
  //   loadChildren: () =>
  //     import('./pages/main/main.module').then((m) => m.MainPageModule),
  //   canActivate: [],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
