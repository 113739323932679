import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfile } from 'src/app/core/models/user-profile.model';
import { UserProfileService } from 'src/app/core/services/firestore-data/user-profile/user-profile.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class CompleteUserProfileGuard  {
  constructor(
    private afAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((user: firebase.User) => {
        if (user){
        if (!user?.isAnonymous) {
          this.userProfileService
            .getCurrentUser()
            .then((userProfile: UserProfile) => {
              if (userProfile?.givenName) {
                resolve(true);
              } else {
                this.router.navigateByUrl('/on-boarding/ask-user-name');
              }
            });
        }} else {
          this.router.navigateByUrl('/auth');
          reject(false);
        }
      });
    });
  }
}
