import { LanguageService } from './core/services/language/language.service';
import { initializeLanguage } from './core/app.initializers';
import { SwiperModule } from 'swiper/angular';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AngularFireAnalyticsModule,
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { registerLocaleData } from '@angular/common';
import deCH from '@angular/common/locales/de-CH';
import frCH from '@angular/common/locales/fr-CH';
import itCH from '@angular/common/locales/it-CH';

import localeDeChExtra from '@angular/common/locales/extra/de-CH';
import localeFrChExtra from '@angular/common/locales/extra/fr-CH';
import localeItChExtra from '@angular/common/locales/extra/it-CH';

import {AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(deCH,localeDeChExtra);
registerLocaleData(frCH,localeFrChExtra);
registerLocaleData(itCH, localeItChExtra);


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        IonicModule.forRoot({
            mode: 'ios',
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAnalyticsModule,
        AngularFireFunctionsModule,
        SwiperModule,
        AngularFirestoreModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, deps: [LanguageService], useFactory: (languageService) => languageService.getCurrentLanguage() },
        { provide: DEBUG_MODE, useValue: !environment.production },
        { provide: REGION, useValue: 'europe-west6' },
        { provide: APP_INITIALIZER, useFactory: () => initializeLanguage, multi: true },
        ScreenTrackingService,
        UserTrackingService,
        provideLottieOptions({
          player: () => import('lottie-web'),
        }),

    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
