import { CookieAlertService } from './../services/cookie-alert/cookie-alert.service';
import { BasketService } from './../services/firestore-data/basket/basket.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, pipe, take } from 'rxjs';
import firebase from 'firebase/compat/app';
import { Basket } from '../models/shop/basket/baket.model';
import { BasketState } from '../models/enums/basket-state.enum';
import { UserProfileService } from '../services/firestore-data/user-profile/user-profile.service';
import { UserProfile } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private afAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private basketService: BasketService,
    private cookieAlertService: CookieAlertService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(async (resolve, reject) => {
      // take first emitted value from authState
      this.afAuth.authState.pipe(take(1)).subscribe((user: firebase.User) => {
        if (user) {
          resolve(true);
        } else {
          this.router.navigate(['/auth']);
          resolve(false);
        }
      });




    });
  }
}
